import React from 'react';

function ButtonSecondary(props) {
    let cssClass = 'btn-secondary ';

    if(props.cssClass !== undefined){
        cssClass += props.cssClass;
    }

    return (
        <button disabled={props.disabled}
                onClick={props.clickHandler}
                className={cssClass}>
            {props.translationKey}
        </button>
    )
}

export default ButtonSecondary;