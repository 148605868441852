import axios from 'axios';
import apiUrl from './index';

export async function validateSession(emailActionKey) {
  let requestUrl =
    apiUrl.host +
    apiUrl.path.validateSession +
    '?emailActionKey=' +
    emailActionKey;

    console.log(requestUrl)
  return axios.get(requestUrl);
}
