import axios from 'axios';
import apiUrl from './index';

export async function registerCertificate(validUntilDate) {
  const { attendee } = window.localStorage;
  let requestUrl =
    apiUrl.host +
    apiUrl.path.registerCeylon +
    '/' +
    attendee +
    '/registerCertificate';

  let validUntil = validUntilDate?.toUTC().toISO();
  return axios({
    method: 'post',
    url: requestUrl,
    data: {
      validUntil,
    },
  });
}
