export function compareNames(one, two) {

    one = partializeName(one);
    two = partializeName(two);
    const matches = one.filter(e => e.some(str => two.some(e2 => e2.includes(str))));
    return (matches[0]?.length >= 2);

    function partializeName(name) {
        return name.toLowerCase().split(/[., ]/).map(str => {

            const normalized = str
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')

            const umlautConverted = str
                .replace('ä', 'ae')
                .replace('ö', 'oe')
                .replace('ü', 'ue')

            return [normalized, umlautConverted]
        });
    };
};
