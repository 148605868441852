import ButtonSecondary from "./components/ButtonSecondary";
import ButtonPrimary from "./components/ButtonPrimary";
import { useEffect, useRef, useState, useContext } from "react";
import { UserContext } from "./AppRoutes";
import { useHistory } from "react-router-dom";
import { validateSession } from "./api";
import png from "png.js";
import jsQR from "jsqr";
import jpeg from "jpeg-js";
import { validateCertificate } from './functions/validateCertificate';
import { registerCertificate } from './api'

function App() {
  const history = useHistory();
  const inputRef = useRef();
  const [fileName, setFileName] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const emailActionKey = urlParams.get("emailActionKey");
  const { userData, setUserData } = useContext(UserContext);
  const [fileType, setFileType] = useState(null);
  const [image, setImage] = useState(null);



  const scanHandler = () => {
    history.push("/qrScanner");
  };

  const openUpload = () => {
    inputRef.current.click();
  };

  const invalidCertificate = () => {
    history.push("/invalidCertificate");
  };

  const invalidPhoto = () => {
    history.push("/invalidPhoto");
  };

  const validationCompleted = () => {
    history.push("/success");
  };

  function convertPNGtoByteArray(pngData) {
    const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
    for (let y = 0; y < pngData.height; y++) {
      for (let x = 0; x < pngData.width; x++) {
        const pixelData = pngData.getPixel(x, y);

        data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
        data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
        data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
        data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
      }
    }
    return data;
  }

  async function pngProcessing(image) {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(image);
    fileReader.onload = function (event) {
      const pngReader = new png(event.target.result);
      pngReader.parse(async function (err, pngData) {
        const pixelArray = convertPNGtoByteArray(pngData);
        if (!jsQR(pixelArray, pngData?.width, pngData?.height)?.data) invalidPhoto();
        const { data } = jsQR(pixelArray, pngData?.width, pngData?.height);
        const date = await validateCertificate(data, userData);
        if(!date) invalidCertificate();
        else registerCertificate(date).then((response) => {
          if (response.status === 200){
            validationCompleted();
          } else {
            invalidCertificate();
          }
        }).catch((error) => {
          invalidCertificate();
        });
      });
    };
  }

  async function jpgProcessing(image) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(image);
    fileReader.onload = async function (event) {
      let rawImageData = jpeg.decode(event.target.result, { useTArray: true });
      let clampedArray = new Uint8ClampedArray(rawImageData.data.length);
      for (let i = 0; i < rawImageData?.data?.length; i++) {
        clampedArray[i] = rawImageData?.data[i];
      }

      let imgobj = new ImageData(clampedArray, rawImageData.width);
      if (!jsQR(imgobj?.data, imgobj?.width, imgobj?.height)?.data) invalidPhoto();
      else {
        const { data } = jsQR(imgobj?.data, imgobj?.width, imgobj?.height);
        const date = await validateCertificate(data, userData);
        if(!date) invalidCertificate();
        else registerCertificate(date).then((response) => {
          if (response.status === 200){
            validationCompleted();
          } else {
            invalidCertificate();
          }
        }).catch((error) => {
          invalidCertificate();
        });
      }

    };
  }

  const uploadHandler = () => {
    setFileName(inputRef.current.files[0]?.name);
    setIsFilePicked(true);
    let fileInput = inputRef.current;
    setFileType(fileInput?.files[0]?.type);
    setImage(fileInput?.files[0]);
  };

  const submitButton = () => {
    switch (fileType) {
      case "image/png":
        pngProcessing(image);
        break;
      case "image/jpeg":
        jpgProcessing(image);
        break;
      default:
        invalidCertificate();
        break;
    }
  }
  const removeFile = () => {
    setFileName(null);
    setIsFilePicked(false);
  }

  useEffect(() => {
    if (emailActionKey) {
      validateSession(emailActionKey).then((response) => {
        window.localStorage.setItem("validKey", true);
        window.localStorage.setItem("attendee", response.data._id);
        setUserData(response.data);
        history.push('/');
      }).catch((error) => {
        history.push('/invalidKey');
      });
    }
  }, []);

  return (
    <div className="app-root">
      <img
        className={"logo"}
        alt={"logo"}
        src={process.env.PUBLIC_URL + "/images/logo_text.svg"}
      />
      <p className={"mainPage-description"}>
        Thank you for completing the ‘Attendees Information Form’ before
        attending the event. We would like to ask you to check the pre-filled
        input fields and complete the missing information. We are looking
        forward to see you at the event.
      </p>
      <h6 className={"covid-info"}>COVID Validation*</h6>
      <p className={"mainPage-description"}>
        Please validate a document like your vaccination certificate, COVID test
        result or recovery report.
      </p>
      <input
        className={"hidden"}
        type="file"
        onChange={uploadHandler}
        accept="image/*"
        ref={inputRef}
      />
      <div className={"buttons-wrapper"}>
        <ButtonSecondary
          clickHandler={scanHandler}
          cssClass={"button"}
          translationKey={"Scan QR Code"}
        />
        <ButtonSecondary
          clickHandler={openUpload}
          cssClass={"button"}
          translationKey={"Import from device"}
        />
      </div>
      {isFilePicked ? 
        <div className="fileName">
          <p>{fileName}</p>
          <p className={"report-problem-close-icon"} onClick={() => removeFile(fileName)}>×</p>
        </div>
       : null
      }
      <div className={"main-button-send"}>
        <ButtonPrimary
          cssClass={"buttonSend"}
          translationKey={"Send"}
          clickHandler={submitButton}
        />
      </div>
    </div>
  );
}

export default App;
