import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

function InvalidAccessPage() {

  return (
    <div className="app-root">
      <img
        className={"logo"}
        alt={"logo"}
        src={process.env.PUBLIC_URL + "/images/logo_text.svg"}
      />
      <FontAwesomeIcon className="times-circle" icon={faTimesCircle} />
      <p className={"errorPage-description"}>
        You do not have access to this page. 
      </p>
    </div>
  );
}

export default InvalidAccessPage;
