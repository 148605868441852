import React from 'react';

function ButtonPrimary(props) {

    let cssClass = 'btn-primary ';

    if(props.cssClass !== undefined){
        cssClass += props.cssClass;
    }

    return (
        <button disabled={props.disabled}
                onClick={props.clickHandler}
                className={cssClass}>
            {props.translationKey}
        </button>
    )
}

export default ButtonPrimary;