import { useContext } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router';
import ButtonPrimary from './components/ButtonPrimary';
import { validateCertificate } from './functions/validateCertificate';
import { UserContext } from './AppRoutes';
import { registerCertificate } from './api';

function QRScanner(props) {
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const redirectToHome = () => {
    history.goBack();
  };

  const validationCompleted = () => {
    history.push('/success');
  };

  const invalidCertificate = () => {
    history.push('/invalidCertificate');
  };

  const handleScan = async (data) => {
    if (data !== null) {
      if (data.substring(0, 3) === 'HC1') {
        try {
          const validUntilDate = await validateCertificate(data, userData);
          if (validUntilDate) {
            registerCertificate(validUntilDate).then((response) => {
              if (response.status === 200) validationCompleted();
            });
          } else invalidCertificate();
        } catch (error) {
          console.log(error);
          invalidCertificate();
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    height: 250,
    width: 250,
  };

  return (
    <div className={'qr-reader-wrapper'}>
      <QrReader
        delay={100}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
        className={'qr-reader-view'}
      />
      <p>{'Please scan the QR Code!'}</p>
      <ButtonPrimary
        cssClass={'buttonQrReader'}
        translationKey={'Back'}
        clickHandler={redirectToHome}
      />
    </div>
  );
}
export default QRScanner;
