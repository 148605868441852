import * as react from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useState } from "react";

import App from './App';
import QRScanner from './QRScanner';
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';
import InvalidAccessPage from './InvalidAccessPage';
import InvalidPhoto from './InvalidPhoto';


export const UserContext = react.createContext({
  userData: '',
  setUserData: () => { },
});

function AppRoutes() {
  const [userData, setUserData] = useState('John Smith');
  const value = react.useMemo(() => ({ userData, setUserData }), [userData]);
  return (
    <UserContext.Provider value={value}>
      <Router>
        <Switch>
          <Route exact path={'/'} render={() => (<App />)} />
          <Route exact path={'/success'} render={() => (<SuccessPage />)} />
          <Route exact path={'/qrScanner'} render={() => (<QRScanner />)} />
          <Route exact path={'/invalidCertificate'} render={() => (<ErrorPage />)} />
          <Route exact path={'/invalidKey'} render={() => (<InvalidAccessPage />)} />
          <Route exact path={'/invalidPhoto'} render={() => (<InvalidPhoto />)} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default AppRoutes;
