import { DateTime } from 'luxon';
import { compareNames } from './compareNames';

export function validateCertificate(data, userData) {

  return new Promise((resolve, reject) => {
  try {
    window
      .EuDgc_parse(data)
      .then((response) => {
        if (response.v) {
          let validUntilDate = DateTime.fromFormat(
            response?.v[0]?.dt,
            'yyyy-MM-dd'
          )
            .toUTC()
            .plus({ years: 1 });
          if (
            compareNames(
              response.nam?.gn + ' ' + response.nam?.fnt,
              userData?.name?.full
            )
          ) {
            if (DateTime.now().toUTC() < validUntilDate) {
              resolve(validUntilDate);
            } else reject('invalid date: ');
          } else reject('names do not match: ');
        } else if (response.r) {
          let validUntilDate = DateTime.fromFormat(
            response?.r[0]?.du,
            'yyyy-MM-dd'
          ).toUTC();
          if (
            compareNames(
              response.nam?.gn + ' ' + response.nam?.fnt,
              userData?.name?.full
            )
          ) {
            if (DateTime.now().toUTC() < validUntilDate) {
              resolve(validUntilDate);
            } else reject('invalid date: ');
          } else reject('names do not match: ');
        }
      })
      .catch((error) => {
        reject('not a eudgc qrcode: ' + error);
      });
  } catch (error) {
    reject('not a eudgc qrcode: ' + error);
  }
});
}
