
const apiUrl = {
    host: process.env.REACT_APP_ENGINE_HOST_URL,
    path: {
        validateSession: '/wengeAttendee/session/validate',
        registerCeylon: '/wengeAttendee/ceylon',
    }
};

export {
    validateSession
} from './session';

export {
    registerCertificate
} from './ceylon';

export default apiUrl;