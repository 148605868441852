import ButtonPrimary from "./components/ButtonPrimary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router";

function SuccessPage() {
  const history = useHistory();

  const ok = () => {
    history.push("/");
  };

  return (
    <div className="app-root">
      <img
        className={"logo"}
        alt={"logo"}
        src={process.env.PUBLIC_URL + "/images/logo_text.svg"}
      />
      <FontAwesomeIcon className="circle-check" icon={faCheckCircle} />
      <p className={"successPage-description"}>
        You have successfully registered your covid certificate. <br/>
        Thank you! <br/>
        You can now close the application.
      </p>
      <div className={"main-button-ok"}>
        <ButtonPrimary
          cssClass={"buttonOk"}
          translationKey={"Ok"}
          clickHandler={ok}
        />
      </div>
    </div >
  );
}

export default SuccessPage;
