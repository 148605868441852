import ButtonPrimary from "./components/ButtonPrimary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router";

function ErrorPage() {
  const history = useHistory();

  const back = () => {
    history.push('/');
  };

  return (
    <div className="app-root">
      <img
        className={"logo"}
        alt={"logo"}
        src={process.env.PUBLIC_URL + "/images/logo_text.svg"}
      />
      <FontAwesomeIcon className="times-circle" icon={faTimesCircle} />
      <p className={"errorPage-description"}>
        The provided certificate is invalid. 
      </p>

      <div className={"main-button-ok"}>
        <ButtonPrimary
          cssClass={"buttonBack"}
          translationKey={"Back"}
          clickHandler={back}
        />
      </div>
    </div>
  );
}

export default ErrorPage;
